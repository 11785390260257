import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Login from './pages/login/Login'
import ProjectForm from './pages/projectForm/ProyectForm'
import Projects from './pages/projects/Projects'
import Cohorts from './pages/cohorts/Cohorts'
import Protected from "./components/Protected";
import NavBar from "./components/navbar/Navbar";
import Header from "./components/header/Header";

import "./App.css";

function App() {
    return (
		<div>
	    <BrowserRouter>
    		<div className="screen">
			    <Routes>
						<Route index element={
              <Protected>
                <div>
                  <Header/>
                  <div className="screen-nav-pages d-flex">
                    <div className="screen-pages mx-auto w-100 py-3 px-4 px-md-5">
                      <ProjectForm />
                    </div>
                  </div>
                </div>
              </Protected>} />
						<Route path='/login' element={<Login />} />
						<Route path='/cohorts' element={
				    <Protected>
							<Header/>
							<div className="screen-nav-pages d-flex">
								<div className="screen-pages mx-auto w-100 p-3">
									<Cohorts />
								</div>
							</div>
				    </Protected>}/>
						</Routes>
					</div>
	    </BrowserRouter>
		</div>
    );
}

export default App;
