import axios from "axios";
import {useState, useEffect} from "react";

function Cohorts() {
    const [cohorts, setCohorts] = useState([]);

    function getURL(path){
	let url = window.location.protocol + "//"
	if (window.location.hostname == "localhost"){
	    url += window.location.hostname + ":" + "8000";
	}else{
	    url += "https://app.kurios.la";
	}
	// add relative path
	url += path;  
	return url;
    }

    function getURLFront(path){
	let url = window.location.protocol + "//"
	if (window.location.hostname == "localhost"){
	    url += window.location.hostname + ":"
	}else{
	    url += window.location.hostname
	}
	url += window.location.port + path;  
	return url
    }

    useEffect(() => {
	let get_cohorts_url = getURL("/api/cohorts");
	let token = localStorage.getItem("access");
	axios.get(get_cohorts_url, {headers: { Authorization: `Bearer ${token}` }})
	    .then((response) => {
		console.log(response);
		let student_cohorts = response["data"]["cohorts"];
		setCohorts(student_cohorts);
	    });
	console.log(cohorts);
    }, []);

    return (
	<div className="">
	    <h3>
		Cohorts
	    </h3>

	    <div className="mt-4">
		<ul>
		    {cohorts.map(cohort => {
			let cohort_proyect_url = getURLFront("/cohorts/"+cohort.uuid);
			return <li><a href={cohort_proyect_url}>{cohort.name}</a></li>
			})
		    }
		</ul>
	    </div>
	</div>
    )
}

export default Cohorts;
