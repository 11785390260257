import axios from "axios";
import {useState, useEffect} from "react";
import { useParams } from 'react-router-dom';

import "./projectForm.css";
import lock_icon from "./lock-icon.svg";
import check_icon from "./check-icon.svg";
import warning_icon from "./warning-icon.svg";

import pdf_icon from "./pdf-icon.svg";
import excel_icon from "./excel-icon.svg";
import docx_icon from "./word-icon.svg";
import pptx_icon from "./ppt-icon.svg";
import nofile_icon from "./nofile-icon.svg";

function ProyectForm() {
  const [projectsJson, setProjectsJson] = useState({});
  const [downloadFirst, setDownloadFirst] = useState(true);
  const [downloadSec, setDownloadSec] = useState(true);
    
  function getURL(path){
    let url = window.location.protocol + "//"
    if (window.location.hostname == "localhost"){
        url += window.location.hostname + ":" + "8000";
    }else{
        url = "https://app.kurios.la";
    }
    // add relative path
    url += path;  
    return url;
  }

  function getFileName(url){
    return url.split('/')[3];
  }

  function getFileExtensionSvg(url){
    let filename = getFileName(url);
    let extension_map = {
      "pdf" : pdf_icon,  
      "xlsx" : excel_icon,  
      "docx" : docx_icon,  
      "pptx" : pptx_icon,  
    }

    let extension = filename.split('.').pop();
    if (extension_map.hasOwnProperty(extension)){
      return extension_map[extension];
    }else{
      return nofile_icon;
    }
  }

  useEffect(() => {
    let get_project_info_url = getURL(`/api/projects`);
    axios.get(get_project_info_url, {headers: { Authorization: `Bearer ${localStorage.getItem("access")}` }})
        .then((response) => {
      let projects_json = response["data"]["projects"];
      console.log(projects_json);
  		document.title = "Kurios | Proyectos";
      setProjectsJson(projects_json);
        });
  }, []);

  function validateInputs(file){
    let alert = document.getElementById("alert-input");

    if (!file){
        alert.removeAttribute("hidden");
        alert.innerHTML = "Suba un archivo";
        return false
    }
    return true
  }

  function submitForm(event, project) {
    event.preventDefault();
    var files = event.target.file_input.files;
    var file = files[0];
    //let ok = validateInputs(file);
    if (true){
      getSignedRequest(file, project);
    }
  }

  //function sendEmailForm(event){
  //  event.preventDefault();
  //  let data = {email: event.target.email_student.value , file_url: event.target.file_url.value};
  //  let send_form_url = getURL(`/student/send_file_to_student`);
  //  axios.post(send_form_url, data, {
  //    headers:{
  //        Authorization: `Bearer ${localStorage.getItem("access")}`,
  //        "Content-Type": "multipart/form-data",
  //    }})
  //    .then((response) => {
  //      console.log(response);
  //    });
  //}

  function getSignedRequest(file, project){
    var xhr = new XMLHttpRequest();
    let get_url_s3 = getURL("/get-s3-upload-info");
    xhr.open("GET", get_url_s3 + "?file_name="+file.name+"&file_type="+file.type);
    xhr.onreadystatechange = function(){
      if(xhr.readyState === 4){
        if(xhr.status === 200){
          var response = JSON.parse(xhr.responseText);
          uploadFile(file, response.data, response.url, response.file_name, project);
        }
        else{
          alert("No se pudo subir el archivo, intenta nuevamente");
        }
      }
    };
    xhr.send();
  }

  function uploadFile(file, s3Data, url, file_name, project){
    var xhr = new XMLHttpRequest();
    xhr.open("POST", s3Data.url);

    var postData = new FormData();
    for(let key in s3Data.fields){
        postData.append(key, s3Data.fields[key]);
    }
    postData.append('file', file);

    //document.getElementById('progressFile').parentNode.removeAttribute("hidden");

    //xhr.upload.onprogress = e => {
    //    if (e.lengthComputable) {
    //	const percentComplete = parseInt((e.loaded / e.total) * 100);
    //	document.getElementById('progressFile').style.width = `${percentComplete}%`;
    //	document.getElementById('progressFile').innerHTML = `${percentComplete}%`;
    //    }
    //};

    xhr.onreadystatechange = function() {
	    if(xhr.readyState === 4){
        if(xhr.status === 200 || xhr.status === 204){
          let data = {project: project["id"], file: file_name};
          let send_form_url = getURL(`/api/project/${project["uuid"]}/create-project-file`);
          axios.post(send_form_url, 
          data, {
              headers:{
            Authorization: `Bearer ${localStorage.getItem("access")}`,
            "Content-Type": "multipart/form-data",
              }
          })
          .then((response) => {
              window.location.reload(true);
              //let alert_modal = document.getElementById("alert-input");
              //alert_modal.removeAttribute("hidden");
              //alert_modal.innerHTML = response["data"]["message"];
              //if (response["data"]["saved"]){
              //    alert_modal.classList.add("alert-success");
              //}else{
              //    alert_modal.classList.add("alert-danger");
              //}
          });
        }else{
          alert("No se pudo subir el archivo, intenta nuevamente");
        }
      }
    };
    xhr.send(postData);
  }

  if (Object.keys(projectsJson).length === 0){
    return (
      <div className="mx-auto" style={{maxWidth: "1000px"}}>
        <div className="d-flex justify-content-between">
          <p>
            Hola. No tienes entregas pendientes.
            Si tienes alguna duda, puedes comunicarte a hola@kurios.la.
          </p>
        </div>
      </div>
    )
  }
  else {
    return (
      <>
        {
          // for each cohort
          Object.entries(projectsJson).map(([cohort, values], i) => {
            // for each project
            let course = values["course"];
            let teachable_link = values["teachable_url"];
            let projects = values["projectsList"];

            let pending = projects.filter((project) => (project["status"] == "Pendiente" || project["status"] == "Bloqueado"));
            let done = projects.filter((project) => project["status"] != "Pendiente" && project["status"] != "Bloqueado");

            return <div key={i} className="mx-auto" style={{maxWidth: "1000px"}}>
              <div className="d-flex justify-content-between">
                <h3>Entregables: {course}</h3>
                <a href={teachable_link} style={{textDecoration: "underline", color: "#001251"}}>
                  Regresar al curso online
                </a>
              </div>
              <div className="wrapper w-100">
                <section>
                  <h3>Pendientes</h3>
                  {
                    pending.length == 0 ?
                      <div className="wrapper project-warning">
                        No hay proyectos pendientes
                      </div>
                      :
                      pending.map((project, i) => {
                        return <>
                          {
                            project["status"] == "Pendiente" ?
                              <div className="wrapper">
                                <div className="d-flex justify-content-between">
                                  <h6>{project["name"]}</h6>
                                  <div>
                                    <img className="icon-space" src={warning_icon}
                                         style={{width: "20px", height: "17.6px"}} alt="Warning Icon"/>
                                    <span style={{color: "C70000"}}>{project["deadline"]}</span>
                                  </div>
                                </div>
                                <ol>
                                  <li>
                                    Elige el archivo pertinente al entregable. Toma en cuenta que solo es necesario
                                    que una persona del grupo suba el archivo.
                                  </li>
                                  <li>
                                    Una vez entregado, nuestro equipo lo revisará y recibirás una confirmación por
                                    correo
                                    electrónico
                                  </li>
                                </ol>
                                <div className="alert" id="alert-input" role="alert" hidden></div>
                                <form method="post" onSubmit={(event) => submitForm(event, project)}>
                                  <div className="d-flex">
                                    <input name="file_input" className="form-control w-50" type="file" required/>
                                    <button className="kurios-btn btn">Subir Archivo</button>
                                  </div>
                                  <div className="progress mt-5" hidden>
                                    <div id="progressFile" className="progress-bar text-center" role="progressbar"
                                         aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                  </div>
                                </form>
                              </div>
                              :
                              <div className="wrapper d-flex justify-content-between project-locked">
                                <div>{project["name"]}</div>
                                <div>
                                  <img src={lock_icon} style={{width: "20px", height: "17.6px"}} alt="Status Icon"/>
                                  {project["deadline"]}
                                </div>
                              </div>
                          }
                        </>
                      })
                  }
                </section>

                <section className="mt-3">
                  <h3>Entregados</h3>
                  {
                    done.length == 0 ?
                      <div className="wrapper project-warning">
                        No hay proyectos pendientes
                      </div>
                      :
                      done.map((project, i) => {
                        if (project["status"] == "Revisado") {
                          return <div className="wrapper d-flex justify-content-between">
                            <div>{project["name"]}</div>
                            <div>
                              <a href={project["document"]["get_review_file_url"]} className="kurios-btn btn">Descargar
                                Feedback</a>
                            </div>
                          </div>
                        } else if (project["status"] == "En Revision") {
                          return <div className="wrapper d-flex justify-content-between">
                            <div>{project["name"]}</div>
                            <div>
                              <img src={check_icon} style={{width: "20px", height: "17.6px"}} alt="Check Icon"/>
                              En Revisión
                            </div>
                          </div>
                        } else {
                          return <div className="wrapper">
                            <div className="d-flex justify-content-between">
                              <h6>{project["name"]}</h6>
                              <div>
                                <img src={check_icon} style={{width: "20px", height: "17.6px"}} alt="Check Icon"/>
                                Entregado
                              </div>
                            </div>
                            <p>
                              ¿Necesitas subir una corrección? Puedes subir tu archivo hasta antes de la fecha límite.
                              &nbsp;
                              {project["deadline"]}
                            </p>
                            <div className="alert" id="alert-input" role="alert" hidden></div>
                            <form method="post" onSubmit={(event) => submitForm(event, project)}>
                              <div className="d-flex">
                                <input name="file_input" className="form-control w-50" type="file" required/>
                                <button className="kurios-btn btn">Subir Archivo</button>
                              </div>
                              <div className="progress mt-5" hidden>
                                <div id="progressFile" className="progress-bar text-center" role="progressbar"
                                     aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                              </div>
                            </form>
                            <p className="mt-2">
                              Revisa tu último archivo subido aquí:
                            </p>
                            <img src={getFileExtensionSvg(project["document"]["get_file_url"])}
                                 style={{width: "20px", height: "20px"}} alt="Check Icon"/>
                            <a
                              href={project["document"]["get_file_url"]}>{getFileName(project["document"]["get_file_url"])}</a>
                          </div>
                        }
                      })
                  }
                </section>
              </div>
            </div>
          })
        }
      </>
    )
  }
}

export default ProyectForm;
