import './login.css';
import microsoft_logo from './Microsoft_logo.svg';
import google_logo from './Google_logo.svg';

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


function generateGoogleLink() {
	const current_url = window.location.host
	if (current_url === "localhost:3000") {
		return "http://localhost:8000/google/oauth2-login/"
	} else {
		return "https://app.kurios.la/google/oauth2-login/"
	}
}

function generateMicrosoftLink() {
	const current_url = window.location.host
	let path;
	if (current_url === "localhost:3000") {
		path = "http://localhost:8000/teams/oauth2-login/"
	} else {
		path = "https://app.kurios.la/teams/oauth2-login/"
	}
	return "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=14e72849-3abf-4b7a-b137-486222aef597&response_type=code&redirect_uri=" + path + "&scope=user.read+openid+profile+email&response_mode=query&state=12345&nonce=678910"
}

function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Ingresa a Kurios";
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryParams = {};

    for (const [key, value] of urlParams.entries()) {
      if (key !== "errors") {
        localStorage.setItem(key, value);
      }
      queryParams[key] = value;
    }

    const goToProjects = () => navigate("/");
    let token = localStorage.getItem("access");
    if (token) {
        goToProjects();
    }
  }, []);

  function getErrors() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    for (const [key, value] of urlParams.entries()) {
      if (key === "errors" && value !== "") {
        return value
      }
    }
    return null
  }

  return (
    <div className="div-body">
      <div className="body-info mt-0 mt-sm-5 pt-5">
        {getErrors() !== null && (
          <div className="alert alert-danger" role="alert">
            {getErrors()}
          </div>
        )}
        <h1 style={{color:"#001251",fontSize:"30px",fontWeight:null}}>Bienvenido a Kurios</h1>
        <br/>
        <p style={{color:"#001251",fontSize:"16px"}}>
          Inicia sesión para entregar tus proyectos de curso
        </p>
        <br/>
        <div className="login-buttons">
          <a href={generateGoogleLink()} className="btn btn-login" style={{backgroundColor:"#F9FAFB",color:"#001251"}}>
            <img src={google_logo} style={{width:"20px", height:"19.6px"}} alt="Google Logo"/>
            <b className="ms-2">Continúa con Google</b>
          </a>
          <br/>
          <a href={generateMicrosoftLink()}  className="btn btn-login" style={{backgroundColor:"#F9FAFB",color:"#001251"}}>
            <img src={microsoft_logo} style={{width:"20px", height:"19.6px"}} alt="Microsoft Logo"/>
            <b className="ms-2">Continúa con Microsoft</b>
          </a>
        </div>
        <br/>
        <br/>
        <p style={{color:"#6C757D",fontSize:"14px"}}>
          Usamos Google y Microsoft solo para autorizar el inicio de sesión. Tu información
          personal no será compartida con nosotros
        </p>
        <br/>
        <br/>
        <p style={{color:"#6C757D",fontSize:"14px"}}>
          ¿Preguntas? Escríbenos a <b style={{color:"#07174E"}}>tech@kurios.la</b>
        </p>
      </div>
    </div>
  )
}

export default Login;
