import kurios_logo from './Kurios-logo.svg';
import Logout from './Logout';

function Header() {
  return (
    <header className="py-4 px-4 px-md-5">
      <div className="d-flex justify-content-between mx-auto" style={{ maxWidth: "1000px" }}>
        <div>
          <img src={kurios_logo} style={{width: "150px", height: "45px"}} alt="Kurios Logo"/>
        </div>
        <div className="d-flex align-items-center">
          <div>
            <a
              href="https://api.whatsapp.com/send?phone=5215591708632&text=Quiero%20ponerme%20en%20contacto%20con%20el%20soporte%20de%20Kurios"
              style={{textDecoration: "underline", color: "#001251"}}>
              ¿Necesitas ayuda?
            </a>
          </div>
          <div className="ms-3">
            <Logout />
          </div>
        </div>
        {/* <img src={user_logo} style={{width:"45px", height:"45px"}} alt="Kurios Logo"/>
      */}
      </div>
    </header>
  )
}

export default Header;
