import {useNavigate} from "react-router-dom";
import logout_logo from "./sign-out-alt-solid.svg";

const Logout = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");
    localStorage.removeItem("user");
    navigate("/login");
  };
  return (
    <a onClick={handleLogout}>
      <img src={logout_logo} style={{height: "20px"}} alt="Logout"/>
    </a>
  );
}

export default Logout;